import css from "@styled-system/css";
import styled from "styled-components";
import { variant } from "styled-system";
import { SxProp } from "../../constants/sx";
import { get } from "../../constants/theme";
import { clampBuilder } from "../../helpers/sizeCalc";

export const Subtitle = styled("span")<SxProp>`
  //29 65
  font-size: 18px;
  font-size: ${clampBuilder(599, 1680, 0.875, 1.5)};
  line-height: 18px;
  font-family: custom_42266;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  color: ${get("palette.text.dark_gold")};
  margin-bottom: ${clampBuilder(599, 1680, 1.8125, 4.0625)};
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 599px) {
    font-size: 24px; //fallback
    font-size: ${clampBuilder(320, 599, 1.15, 1.5)};
    margin-bottom: ${clampBuilder(320, 599, 2.375, 4.375)};
  }

  ${(props) => props.sx && css({ ...props.sx })};
`;

export const Title = styled("h1")<
  SxProp & { variant?: "hero" | "bigHero" | "section" | "paragraph" | "fancy" }
>`
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;

  ${variant({
    variants: {
      hero: {
        fontFamily: "custom_42264",
        letterSpacing: "-0.5px",
        fontSize: clampBuilder(599, 1680, 2, 4.875), //78  //32
        lineHeight: clampBuilder(599, 1680, 2, 4.5), // "32px", //"72px",
      },
      bigHero: {
        fontFamily: "custom_42264",
        letterSpacing: "-0.5px",
        fontSize: clampBuilder(599, 1680, 2.625, 5.375), //"42px", //"86px",
        lineHeight: clampBuilder(599, 1680, 2.5, 5.125), // "40px", //82PX
        opacity: 0.85,
      },
      section: {
        fontFamily: "custom_42263",
        fontSize: clampBuilder(599, 1680, 4.125, 9.25), //"66px", // "148px",
        lineHeight: clampBuilder(599, 1680, 4, 8.625), // "64px", //"138px",
        color: get("palette.text.brown"),
      },
      paragraph: {
        fontSize: clampBuilder(599, 1680, 1.5, 3.625), //"24px", //"58px",
        lineHeight: clampBuilder(599, 1680, 1.5, 3.625), //"24px", //"58px",
        fontFamily: "custom_42264",
        textAlign: "left",
        color: get("palette.text.brown"),
        padding: ["0 5%", "0 5%", 0, 0],
        width: "100%",
      },
      fancy: {
        textTransform: "none",
        letterSpacing: "-0.4px",
        fontFamily: "custom_42269",
        fontSize: clampBuilder(599, 1680, 3.25, 7), // "52px", //"112px",
        lineHeight: clampBuilder(599, 1680, 3, 7), // "48px", //"112px",
        color: get("palette.text.grey_gold"),
      },
    },
  })}

  @media screen and (max-width: 599px) {
    ${variant({
      variants: {
        hero: {
          fontSize: clampBuilder(320, 599, 2, 3.625),
          lineHeight: clampBuilder(320, 599, 2.125, 4), // "34px", //"64px",
        },
        bigHero: {
          fontSize: clampBuilder(320, 599, 2, 5.15), //"46px", //"84px",
          lineHeight: clampBuilder(320, 599, 2.625, 5.35), //"46px", //"84px",
          opacity: 1,
          letterSpacing: "-1px",
        },
        section: {
          fontSize: clampBuilder(320, 599, 2.125, 3.625),
          lineHeight: clampBuilder(320, 599, 2.25, 4.125),
        },
        paragraph: {
          fontSize: clampBuilder(320, 599, 2, 2.3), //"32px", // "56px",
          lineHeight: clampBuilder(320, 599, 2.125, 3.875), //"34px", //"62px",
        },
        fancy: {
          fontSize: clampBuilder(320, 599, 2.25, 4), //"36px", //"64px",
          lineHeight: clampBuilder(320, 599, 3.625, 6.875), //"58px", //"110px",
        },
      },
    })}
  }

  ${(props) => props.sx && css({ ...props.sx })};
`;

export const Paragraph2 = styled("p")<SxProp & { variant: "hero" | "normal" }>`
  font-weight: 400;
  font-style: normal;
  font-family: custom_42266;
  color: rgba(0, 0, 0, 1);
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  ${variant({
    variants: {
      hero: {
        whiteSpace: "pre-wrap",
        textRendering: "optimizelegibility",
        overflowWrap: "break-word",
        textAlign: "center",
        fontSize: clampBuilder(599, 1680, 0.8125, 1.6875), //"13px", //"27px",
        lineHeight: clampBuilder(599, 1680, 1.125, 2.375), //"18px", //"38px",
        opacity: 0.7,
      },
      normal: {
        whiteSpace: "pre-wrap",
        textRendering: "optimizelegibility",
        overflowWrap: "break-word",
        fontSize: clampBuilder(599, 1680, 0.65, 1.4), //"13px", //"27px",
        lineHeight: clampBuilder(599, 1680, 1.25, 2.375), //"18px", //"38px",
        opacity: 0.75,
      },
    },
  })}
  @media screen and (max-width: 599px) {
    ${variant({
      variants: {
        hero: {
          fontSize: clampBuilder(320, 599, 1.125, 2.125), //"18px", //"34px",
          lineHeight: clampBuilder(320, 599, 1.75, 3.25), //"28px", //"52px"
          opacity: 0.75,
        },
        normal: {
          fontSize: clampBuilder(320, 599, 1.125, 2.125), //"18px", //"34px",
          lineHeight: clampBuilder(320, 599, 2, 3.75), //"28px", //"52px"
          opacity: 0.8,
        },
      },
    })}
  }

  ${(props) => props.sx && css({ ...props.sx })};
`;

export const Paragraph = styled("p")<SxProp>`
  font-size: 21px;
  font-size: ${clampBuilder(599, 1680, 0.65, 1.4)};
  line-height: 40px;
  line-height: clamp(1.25rem, -0.269rem + 3.1646vw, 2.5rem);
  font-weight: 400;
  font-style: "normal";
  font-family: "custom_42266";
  text-align: inherit;
  opacity: 0.8;
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 768px) {
    padding: 0 5%;
  }

  @media screen and (max-width: 599px) {
    line-height: 28px;
    font-size: 18px;
    font-size: clamp(1.125rem, -0.6472rem + 4.7337vw, 1.625rem);
  }

  ${(props) => props.sx && css({ ...props.sx })};
`;

export const Listul = styled("ul")<SxProp>`
  font-size: 21px;
  font-size: ${clampBuilder(599, 1680, 0.65, 1.4)};
  line-height: 40px;
  line-height: clamp(1.25rem, -0.269rem + 3.1646vw, 2.5rem);
  font-weight: 400;
  font-style: "normal";
  font-family: "custom_42266";
  text-align: inherit;
  opacity: 0.8;
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  list-style-position: outside;
  margin-left: 24px;
  padding-inline-start: 0;

  @media screen and (max-width: 768px) {
    padding: 0 5%;
  }

  @media screen and (max-width: 599px) {
    line-height: 28px;
    font-size: 18px;
    font-size: clamp(1.125rem, -0.6472rem + 4.7337vw, 1.625rem);
  }

  ${(props) => props.sx && css({ ...props.sx })};
`;

export const Listli = styled("li")<SxProp>`
  list-style-type: "—  ";

  ${(props) => props.sx && css({ ...props.sx })};
`;

export const FormBtn = styled("button")<SxProp>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  text-transform: uppercase;
  font-family: custom_42266;
  font-weight: 400;
  color: ${get("palette.text.dark_gold")};
  font-size: 16px;
  font-size: clamp(1rem, 0.4459rem + 1.4801vw, 1.4rem);
  margin-top: 60px;
  width: 100%;
  min-height: 60px;

  border: 1px solid rgb(187, 171, 145);
  padding: 10px 40px;

  margin-bottom: 2px;
  outline: 0;
  border-radius: 0;
  box-shadow: none;

  @media screen and (max-width: 599px) {
    font-size: 21px;
    line-height: 32px;

    font-size: clamp(1.3125rem, 0.0939rem + 6.0932vw, 2.375rem);
    line-height: ${clampBuilder(320, 599, 1.625, 3.125)};
  }

  ${(props) => props.sx && css({ ...props.sx })};
`;

export const SmallTextWrapper = styled("span")<SxProp>`
  font-family: custom_42266;
  font-weight: 400;
  font-size: 14px;
  font-size: clamp(0.875rem, 0.5287rem + 0.9251vw, 1.5rem);
  line-height: 22px;
  line-height: clamp(1.375rem, 0.7516rem + 1.6651vw, 2.5rem);
  text-align: left;
  display: block;
  margin: 0;
  text-decoration: none;
  text-transform: capitalize;
  color: ${get("palette.text.dark_gold")};
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 599px) {
    text-align: center;
    font-size: 18px;
    font-size: clamp(1.125rem, 0.2648rem + 4.3011vw, 1.875rem);
    line-height: 36px;
    line-height: clamp(2.25rem, -0.0439rem + 11.4695vw, 4.25rem);
  }

  ${(props) => props.sx && css({ ...props.sx })};
`;
