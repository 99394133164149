import themeGet from "@styled-system/theme-get";
import * as styledSystem from "styled-system";

const { get: getKey } = styledSystem;

export const get = (key: string) => themeGet(key, getKey(theme, key));

export const breakpoints = ["600px", "768px", "1200px", "1400px"];

const palette = {
  background: {
    primary: "rgb(255,255,255)",
    secondary: "rgb(196,171,130)",
    gold: "rgb(248,245,240)",
    contact_form: "rgb(67,57,52)",
  },
  text: {
    primary: "rgb(0,0,0)",
    brown: "rgba(56, 45, 34, 1)",
    light_gold: "#CDA96F",
    gold: "rgb(205,195,178)",
    grey_gold: "rgba(187, 171, 145, 1)",
    dark_gold: "rgb(184,171,148)",
    dark_gold_disabled: "rgb(125,114,100)",
    link_btn: "rgba(187, 171, 145, 1)",
  },
  error: "rgb(236,87,57)",
};

const theme = {
  palette,
  breakpoints,
};

export default theme;
